import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functioninternationalbanking = () => {
  return (
    <>
<Helmet>
<title>International Banking | HBL People</title>
<meta
name="description"
content="Global partner bank for clients"
/>
</Helmet>
<section className="innerpagebanner international-banking">
  <div className="container">
    <div className="in" style={{maxWidth: '545px'}}>
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">International Banking</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Global partner bank for clients </h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages international-banking">
  <div className="container d-flex flex-wrap gap-3">
    <p>International Banking (IB) is an integral part of the Bank’s structure, which manages affairs relating to the Bank’s overseas branches, rep. offices, subsidiaries and affiliates. It provides the infrastructure and connectivity which facilitates the efficient functioning of our global trade, payments, and treasury businesses. IB provides a vital platform that fosters the intra-network coordination and seamless execution that allows HBL to serve its clients in multiple important locations, and to contribute to enhancement of regional trade flows.</p>
    <p>In Pakistan, HBL remains the leading bank for China business. HBL’s two branches in China (Beijing & Urumqi) serve as the main engine for origination and execution of Chinese transactions, facilitating greater intra-network flows. The Bank’s successful China coverage model has expanded to UAE, Sri Lanka, Bangladesh, Maldives, and East Africa through our affiliate DTB, serving Chinese State-Owned Enterprises and large private sector corporates. This furthers objective HBL’s objective of being a Bank of relevance in the region. The China franchise is well positioned to become the flagship of the international business over the next few years.</p>
    <p>Financial Institutions and Global Remittances Business (FI-GRB) is a vital component of HBL, responsible for managing all aspects of the bank’s relationships with both domestic and international financial institutions, non-banking financial institutions and money service providers.</p>
    <p>Key features of the department encompass establishing and nurturing FI relationships domestically and across international locations, acting as a conduit for other banks facilitating the structuring of trade-related transactions within our extensive network, promoting trade finance endeavours and home remittances from across the globe. Presently, FI-GRB covers correspondent banking relationships in over 80 international countries including arrangements with over 2,000 banks for HBL network globally. This allows the bank to cater to retail, commercial and corporate clients’ diverse requirements in an efficient manner. </p>
    <p>FI-GRB also offers a comprehensive suite of banking solutions tailored to non-banking financial institutions, encompassing long-term and short-term lending facilities, customized deposit products under both conventional and Islamic modes, innovative digital transaction and employee banking solutions for seamless transactions, consumer banking products, and structured derivatives for hedging market risk.</p>
    <p style={{color: '#333',fontSize: '24px',fontWeight: '400'}}><em>“With its deep expertise and expansive network, FI-GRB continues to enhance HBL’s standing as a leading financial institution. By fostering strong relationships with financial institutions, facilitating trade finance activities, and providing specialized banking solutions to NBFIs, the department contributes significantly to the bank’s growth and success in domestic and international markets.”</em><span className="d-block" style={{color:"#197056"}}><em>Khaqan Khan, Head Financial Institutions and Global Remittance Business.</em></span></p>
  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functioninternationalbanking
