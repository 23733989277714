import { Navigate, useLocation } from 'react-router-dom';

// export const RemoveTrailingSlash = () => {
//   const location = useLocation();
//   const newPathname = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;

//   if (newPathname && newPathname !== location.pathname) {
//     setTimeout(() => {
//       window.location.href = newPathname + location.search;
//     }, 0);
//     return <Navigate replace to={newPathname + location.search} />;
//   }

//   return null;
// };

export const RemoveTrailingSlash = () => {
  const location = useLocation();
  // If the last character of the url is '/'
  if (location.pathname.endsWith('/')) {
    const newPathname = location.pathname.slice(0, -1);
    return <Navigate replace to={newPathname + location.search} />;
  }
  return null;
};
