import React, { useState, useEffect } from 'react';
import {Outlet, NavLink } from "react-router-dom";


const Header = () => {

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 700) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>

<section className="top d-none d-md-block">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-md-4 col-lg-6"> <a href="https://www.hbl.com/" target="_blank" rel="noreferrer noopener"className="hbllink">Visit HBL.com</a> </div>
      <div className="col-md-8 col-lg-6">
        <ul className="right-top-menu text-end">
          <li><a href="https://www.hbl.com/about-us" target="_blank" rel="noreferrer noopener">About Us</a></li>
          <li><a href="https://www.hbl.com/news-and-media" target="_blank" rel="noreferrer noopener">News</a></li>
          <li><a href="https://www.hbl.com/contact-us" target="_blank" rel="noreferrer noopener">Contact us</a></li>
          <li><a href="https://www.hbl.com/resources" target="_blank" rel="noreferrer noopener">Resources</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<header className="home">
<nav className="navbar navbar-expand-lg navbar-light bottom">
<div className="container align-items-center">
<a href="/" className='navbar'><img src="/images/logo.png" alt=""/></a>
<button  className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#rightmenuheader"
                    aria-controls="rightmenuheader" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
<div className="collapse navbar-collapse" id="rightmenuheader">
  <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
    <li className="nav-item"> <a href="/function" className='nav-link'  >Explore HBL</a> </li>
    <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Ways to join us</a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
        <li><a href="/experienced-professionals" className='nav-link'  >Experienced Professionals</a></li>
        <li><a href="/fresh-graduates" className='nav-link'  >Fresh Graduates</a></li>
        <li><a href="https://theleague.hblpeople.com">The League</a></li>
      </ul>
    </li>
    <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">Life at HBL</a>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
        <li><a href="/benefits" className='nav-link'  >Benefits and Sustenance</a></li>
        <li><a href="/culture" className='nav-link'  >Our Culture</a></li>
        <li><a href="/impact" className='nav-link'  >Technology</a></li>
        <li><a href="/diversity" className='nav-link'  >Diversity, Equity and Inclusion</a></li>
        <li><a href="/learning" className='nav-link'  >Learning &amp; Development</a></li>
        <li><a href="/footprint" className='nav-link'  >Global Footprint</a></li>
      </ul>
    </li>

    <li className="last"> <a aria-current="page" href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005"target="_blank" rel="noreferrer noopener">Apply Now</a> </li>
  </ul>
</div>
</div>
</nav>
</header>
<div className={`applynowside ${isSticky ? 'op1' : ''}`}>
<div className=""> <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener"><img src="images/apply.jpg" alt=""/></a> </div>
</div>
<div className="applynowsidemob"> <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005"target="_blank" rel="noreferrer noopener">Apply Now</a> </div>
      
<Outlet />
    </>
  )
}

export default Header
