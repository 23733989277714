import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionbranchoperations = () => {
  return (
    <>
    <Helmet>
<title>Branch Operations and Account Services | HBL People</title>
<meta
name="description"
content="Bank with Confidence – Customer Centricity in a Compliant Banking Environment"
/>
</Helmet>
<section className="innerpagebanner branch-operations">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Branch Operations and Account Services</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Bank with Confidence – Customer Centricity in a Compliant Banking Environment </h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages branch-operations">
  <div className="container d-flex flex-wrap gap-3">
    <p data-sr="enter top over 0.3s and move 65px wait 0.3s">Branch Operations and Account Services is relentlessly and diligently providing banking services to our diverse clientele from multiple business verticals through a network of 1,720+ branches and 2,250+ ATMs on Pan Pakistan basis. We strive to create and deliver excellent client onboarding experience and service delivery through our branches while ensuring adherence to regulatory compliance, mitigate risk(s) and effective controls implementation. Our experienced and trained teams work tirelessly, streamlining the processes and strategically applying technology in process automation. We also lead initiatives related to process simplification to improve the customer experience and help create value for our internal & external clients.</p>
  </div>
</section>

<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionbranchoperations
