import React, { useEffect } from 'react';

const RedirectLinks = () => {
  const linkMap = {
    '/the-league': 'https://theleague.hblpeople.com',
    '/the-league/internship': 'https://theleague.hblpeople.com/internship',
    '/the-league/management-trainee': 'https://theleague.hblpeople.com/management-trainee',
    '/the-league/your-role': 'https://theleague.hblpeople.com/your-role',
    '/the-league/privacy-policy': 'https://www.hbl.com/privacy-protocol',
    '/the-league/team-bonding': 'https://theleague.hblpeople.com/team-bonding',
    '/the-league/ufaqs/finance': 'https://theleague.hblpeople.com/ufaqs/finance',
    '/the-league/self-kinect': 'https://theleague.hblpeople.com/self-kinect',
    '/the-league/ufaqs/eligibility': 'https://theleague.hblpeople.com/ufaqs/eligibility',
    '/the-league/agp-your-role': 'https://theleague.hblpeople.com/agp-your-role',
    '/the-league/accelerate-graduate-programme': 'https://theleague.hblpeople.com/accelerate-graduate-programme',
    '/the-league/application-process-faqs': 'https://theleague.hblpeople.com/application-process-faqs',
    '/the-league/ufaqs/branch-banking': 'https://theleague.hblpeople.com/ufaqs/branch-banking',
    '/the-league/ufaqs/operations-services': 'https://theleague.hblpeople.com/ufaqs/operations-services',
    '/the-league/the-hbl-experience': 'https://theleague.hblpeople.com/the-hbl-experience',
    '/the-league/your-career-path': 'https://theleague.hblpeople.com/your-career-path',
    '/the-league/beach-cleaning-karachi': 'https://theleague.hblpeople.com/beach-cleaning-karachi',
    '/the-league/lunch-with-ted': 'https://theleague.hblpeople.com/lunch-with-ted',
    '/the-league/ufaqs/corporate-strategy': 'https://theleague.hblpeople.com/ufaqs/corporate-strategy',
    '/the-league/ufaqs/risk-management': 'https://theleague.hblpeople.com/ufaqs/risk-management',
    '/the-league/ufaqs/global-compliance': 'https://theleague.hblpeople.com/ufaqs/global-compliance',
    '/the-league/ufaqs/consumer-banking': 'https://theleague.hblpeople.com/ufaqs/consumer-banking',
    '/the-league/book-donation-islamabad': 'https://theleague.hblpeople.com/book-donation-islamabad',
    '/the-league/internship-orientation-2021': 'https://theleague.hblpeople.com/internship-orientation-2021',
    '/the-league/ufaqs/internal-audit': 'https://theleague.hblpeople.com/ufaqs/internal-audit',
    '/the-league/ufaqs/information-technology': 'https://theleague.hblpeople.com/ufaqs/information-technology',
    '/the-league/ufaqs/mentorship-programme': 'https://theleague.hblpeople.com/ufaqs/mentorship-programme',
    '/the-league/ufaqs/contact-us': 'https://theleague.hblpeople.com/ufaqs/contact-us',
    '/the-league/ufaqs/development-finance': 'https://theleague.hblpeople.com/ufaqs/development-finance',
    '/the-league/ufaqs/islamic-banking': 'https://theleague.hblpeople.com/ufaqs/islamic-banking',
    '/the-league/ufaqs/apply-now': 'https://theleague.hblpeople.com/ufaqs/apply-now',
    '/the-league/lse-campus-drive-2019': 'https://theleague.hblpeople.com/lse-campus-drive-2019',
    '/the-league/ksbl-campus-drive-2018': 'https://theleague.hblpeople.com/ksbl-campus-drive-2018',
    '/the-league/agp-application-process-faqs': 'https://theleague.hblpeople.com/agp-application-process-faqs',
    '/the-league/lse-campus-drive-2020': 'https://theleague.hblpeople.com/lse-campus-drive-2020',
    '/the-league/iobm-campus-drive-2018': 'https://theleague.hblpeople.com/iobm-campus-drive-2018',
    '/the-league/the-league-assessment-centre': 'https://theleague.hblpeople.com/the-league-assessment-centre',
    '/the-league/iba-campus-drive-2020': 'https://theleague.hblpeople.com/iba-campus-drive-2020',
    '/the-league/ufaqs/treasury-global-markets': 'https://theleague.hblpeople.com/ufaqs/treasury-global-markets',
    '/the-league/agp-your-career-path': 'https://theleague.hblpeople.com/agp-your-career-path',
    '/the-league/iobm-campus-drive-2020': 'https://theleague.hblpeople.com/iobm-campus-drive-2020',
    '/the-league/lums-campus-drive-2020': 'https://theleague.hblpeople.com/lums-campus-drive-2020',
    '/the-league/iba-career-fair-2018': 'https://theleague.hblpeople.com/iba-career-fair-2018',
    '/the-league/life-in-the-league': 'https://theleague.hblpeople.com/life-in-the-league',
    '/the-league/iba-campus-drive-2022': 'https://theleague.hblpeople.com/iba-campus-drive-2022',
    '/the-league/assessment-centre-lahore-2019': 'https://theleague.hblpeople.com/assessment-centre-lahore-2019',
    '/the-league/iobm-career-fair-2020': 'https://theleague.hblpeople.com/iobm-career-fair-2020',
    '/the-league/ufaqs/informational-technology-security': 'https://theleague.hblpeople.com/ufaqs/informational-technology-security',
    '/the-league/ufaqs/corporate-investment-banking': 'https://theleague.hblpeople.com/ufaqs/corporate-investment-banking',
    '/the-league/gik-campus-drive-2020': 'https://theleague.hblpeople.com/gik-campus-drive-2020',
    '/the-league/ufaqs/career-orientation-mapping': 'https://theleague.hblpeople.com/ufaqs/career-orientation-mapping',
    '/the-league/assessment-centre-karachi-2019': 'https://theleague.hblpeople.com/assessment-centre-karachi-2019',
    '/the-league/ufaqs/apply-now-2': 'https://theleague.hblpeople.com/ufaqs/apply-now-2',
    '/the-league/assessment-centre-islamabad-2019': 'https://theleague.hblpeople.com/assessment-centre-islamabad-2019',
    '/the-league/ufaqs/contact-us-2': 'https://theleague.hblpeople.com/ufaqs/contact-us-2',
    '/the-league/the-league-2017-journey': 'https://theleague.hblpeople.com/the-league-2017-journey',
    '/the-league/the-league-ibas-campus-drive': 'https://theleague.hblpeople.com/the-league-ibas-campus-drive',
    '/the-league/the-league-orientation-ceremony-2019': 'https://theleague.hblpeople.com/the-league-orientation-ceremony-2019',
    '/the-league/habib-university-campus-drive-2020': 'https://theleague.hblpeople.com/habib-university-campus-drive-2020',
    '/the-league/the-league-nust-campus-drive': 'https://theleague.hblpeople.com/the-league-nust-campus-drive',
    '/the-league/the-league-2018-orientation-ceremony': 'https://theleague.hblpeople.com/the-league-2018-orientation-ceremony',
    '/the-league/the-league-iba-campus-drive': 'https://theleague.hblpeople.com/the-league-iba-campus-drive',
    '/the-league/what-do-we-look-for': 'https://theleague.hblpeople.com/what-do-we-look-for',
    '/the-league/the-league-lse-campus-drive': 'https://theleague.hblpeople.com/the-league-lse-campus-drive',
    '/the-league/ufaqs/innovation-and-financial-inclusion': 'https://theleague.hblpeople.com/ufaqs/innovation-and-financial-inclusion',
    '/the-league/summer-internship-2017-orientation-ceremony': 'https://theleague.hblpeople.com/summer-internship-2017-orientation-ceremony',
    '/the-league/the-league-orientation-ceremony-2018': 'https://theleague.hblpeople.com/the-league-orientation-ceremony-2018',
    '/the-league/ufaqs/human-resources-and-organizational-development': 'https://theleague.hblpeople.com/ufaqs/human-resources-and-organizational-development',
    '/the-league/agp-what-do-we-look-for': 'https://theleague.hblpeople.com/agp-what-do-we-look-for',
    '/applynow': 'https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005',

  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const externalUrl = linkMap[currentPath];
    if (externalUrl) {
      window.location.href = externalUrl;
    } 
    // else {
    //   // Redirect to a default page if the current path is not found in linkMap
    //   window.location.href = 'https://www.hblpeople.com/404';
    // }
  }, []);

  //return <p>Redirecting...</p>;
  return <></>;
};

export default RedirectLinks;