import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Experienced from "./pages/Experienced";
import Graduates from "./pages/graduates";
import Benefits from "./pages/Benefits";
import Culture from "./pages/Culture";
import Impact from "./pages/Impact";
import Diversity from "./pages/Diversity";
import Learning from "./pages/Learning";
import Footprint from "./pages/Footprint";
import Function from "./pages/Function";
import Disclaimer from "./pages/Disclaimer";
import Privacypolicy from "./pages/privacy-policy";
// function detail
import Functionbranchbanking from "./pages/function_details/Function-branch-banking";
import Functionbranchoperations from "./pages/function_details/Function-branch-operations";
import Functionchinabusiness from "./pages/function_details/Function-china-business";
import Functionconsumeragriculture from "./pages/function_details/Function-consumer-agriculture";
import Functioncorporateandinvestmentbanking from "./pages/function_details/Function-corporate-and-investment-banking";
import Functioncorporatesecretariat from "./pages/function_details/Function-corporate-secretariat";
import Functioncorporatestrategy from "./pages/function_details/Function-corporate-strategy";
import Functiondevelopmentfinance from "./pages/function_details/Function-development-finance";
import Functionfinance from "./pages/function_details/Function-finance";
import Functionglobalcompliance from "./pages/function_details/Function-global-compliance";
import Functionhumanresources from "./pages/function_details/Function-human-resources";
import Functioninformationsecurity from "./pages/function_details/Function-information-security";
import Functioninformationtechnology from "./pages/function_details/Function-information-technology";
import Functioninnovationfinancialinclusion from "./pages/function_details/Function-innovation-financial-inclusion";
import Functioninternalaudit from "./pages/function_details/Function-internal-audit";
import Functioninternationalbanking from "./pages/function_details/Function-international-banking";
import Functionislamicbanking from "./pages/function_details/Function-islamic-banking";
import Functionlegalaffairs from "./pages/function_details/Function-legal-affairs";
import Functionmarketingcorporateaffairs from "./pages/function_details/Function-marketing-corporate-affairs";
import Functionoperationservices from "./pages/function_details/Function-operation-services";
import Functionremedialstructuredcredit from "./pages/function_details/Function-remedial-structured-credit";
import Functionservicequality from "./pages/function_details/Function-service-quality";
import Functiontreasuryglobalmarkets from "./pages/function_details/Function-treasury-global-markets";
import Functionriskmanagement from "./pages/function_details/Function-risk-management";
import { RemoveTrailingSlash } from "./components/RemoveTrailingSlash";
import RedirectLinks from "./pages/Redirectlinks";
// import reportWebVitals from './reportWebVitals';

export default function App() {

  return (
    <>

      <Router>
        <RemoveTrailingSlash />
        {/* <RedirectLinks/> */}
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/home/01-2" element={<Navigate to="/" replace />} />
            <Route path="/experienced-professionals" element={<Experienced />} />
            <Route path="/fresh-graduates" element={<Graduates />} />
            <Route path="/graduates-and-trainees" element={<Navigate to="/fresh-graduates" replace />} />
            <Route path="/benefits" element={<Benefits />} />
            <Route path="/culture" element={<Culture />} />
            <Route path="/impact" element={<Impact />} />
            <Route path="/diversity" element={<Diversity />} />
            <Route path="/learning" element={<Learning />} />
            <Route path="/learning-development" element={<Navigate to="/learning" replace />} />
            <Route path="/footprint" element={<Footprint />} />
            <Route path="/global-footprint" element={<Navigate to="/footprint" replace />} />
            <Route path="/function" element={<Function />} />
            <Route path="/divisions" element={<Navigate to="/function" replace />} />
            <Route path="/all-divisions" element={<Navigate to="/function" replace />} />
            <Route path="/function-branch-banking" element={<Functionbranchbanking />} />
            <Route path="/function-branch-operations" element={<Functionbranchoperations />} />
            <Route path="/function-china-business" element={<Functionchinabusiness />} />
            <Route path="/function-consumer-agriculture" element={<Functionconsumeragriculture />} />
            <Route path="/function-corporate-and-investment-banking" element={<Functioncorporateandinvestmentbanking />} />
            <Route path="/function-corporate-secretariat" element={<Functioncorporatesecretariat />} />
            <Route path="/function-corporate-strategy" element={<Functioncorporatestrategy />} />
            <Route path="/function-development-finance" element={<Functiondevelopmentfinance />} />
            <Route path="/function-finance" element={<Functionfinance />} />
            <Route path="/function-global-compliance" element={<Functionglobalcompliance />} />
            <Route path="/function-human-resources" element={<Functionhumanresources />} />
            <Route path="/function-information-security" element={<Functioninformationsecurity />} />
            <Route path="/function-information-technology" element={<Functioninformationtechnology />} />
            <Route path="/function-innovation-financial-inclusion" element={<Functioninnovationfinancialinclusion />} />
            <Route path="/function-internal-audit" element={<Functioninternalaudit />} />
            <Route path="/function-international-banking" element={<Functioninternationalbanking />} />
            <Route path="/function-islamic-banking" element={<Functionislamicbanking />} />
            <Route path="/function-legal-affairs" element={<Functionlegalaffairs />} />
            <Route path="/function-marketing-corporate-affairs" element={<Functionmarketingcorporateaffairs />} />
            <Route path="/function-operation-services" element={<Functionoperationservices />} />
            <Route path="/function-remedial-structured-credit" element={<Functionremedialstructuredcredit />} />
            <Route path="/function-risk-management" element={<Functionriskmanagement />} />
            <Route path="/function-service-quality" element={<Functionservicequality />} />
            <Route path="/function-treasury-global-markets" element={<Functiontreasuryglobalmarkets />} />
            {/* redirect */}
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="/the-league/privacy-policy" element={<Privacypolicy />} />
            <Route path="/the-league" element={<RedirectLinks/>} />
            <Route path="/the-league/internship" element={<RedirectLinks/>} />
            <Route path="/the-league/management-trainee" element={<RedirectLinks/>} />
            <Route path="/the-league/your-role" element={<RedirectLinks/>} />
            <Route path="/the-league/team-bonding" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/finance" element={<RedirectLinks/>} />
            <Route path="/the-league/self-kinect" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/eligibility" element={<RedirectLinks/>} />
            <Route path="/the-league/agp-your-role" element={<RedirectLinks/>} />
            <Route path="/the-league/accelerate-graduate-programme" element={<RedirectLinks/>} />
            <Route path="/the-league/application-process-faqs" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/branch-banking" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/operations-services" element={<RedirectLinks/>} />
            <Route path="/the-league/the-hbl-experience" element={<RedirectLinks/>} />
            <Route path="/the-league/your-career-path" element={<RedirectLinks/>} />
            <Route path="/the-league/beach-cleaning-karachi" element={<RedirectLinks/>} />
            <Route path="/the-league/lunch-with-ted" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/corporate-strategy" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/risk-management" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/global-compliance" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/consumer-banking" element={<RedirectLinks/>} />
            <Route path="/the-league/book-donation-islamabad" element={<RedirectLinks/>} />
            <Route path="/the-league/internship-orientation-2021" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/internal-audit" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/information-technology" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/mentorship-programme" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/contact-us" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/development-finance" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/islamic-banking" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/apply-now" element={<RedirectLinks/>} />
            <Route path="/the-league/lse-campus-drive-2019" element={<RedirectLinks/>} />
            <Route path="/the-league/ksbl-campus-drive-2018" element={<RedirectLinks/>} />
            <Route path="/the-league/agp-application-process-faqs" element={<RedirectLinks/>} />
            <Route path="/the-league/lse-campus-drive-2020" element={<RedirectLinks/>} />
            <Route path="/the-league/iobm-campus-drive-2018" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-assessment-centre" element={<RedirectLinks/>} />
            <Route path="/the-league/iba-campus-drive-2020" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/treasury-global-markets" element={<RedirectLinks/>} />
            <Route path="/the-league/agp-your-career-path" element={<RedirectLinks/>} />
            <Route path="/the-league/iobm-campus-drive-2020" element={<RedirectLinks/>} />
            <Route path="/the-league/lums-campus-drive-2020" element={<RedirectLinks/>} />
            <Route path="/the-league/iba-career-fair-2018" element={<RedirectLinks/>} />
            <Route path="/the-league/life-in-the-league" element={<RedirectLinks/>} />
            <Route path="/the-league/iba-campus-drive-2022" element={<RedirectLinks/>} />
            <Route path="/the-league/assessment-centre-lahore-2019" element={<RedirectLinks/>} />
            <Route path="/the-league/iobm-career-fair-2020" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/informational-technology-security" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/corporate-investment-banking" element={<RedirectLinks/>} />
            <Route path="/the-league/gik-campus-drive-2020" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/career-orientation-mapping" element={<RedirectLinks/>} />
            <Route path="/the-league/assessment-centre-karachi-2019" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/apply-now-2" element={<RedirectLinks/>} />
            <Route path="/the-league/assessment-centre-islamabad-2019" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/contact-us-2" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-2017-journey" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-ibas-campus-drive" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-orientation-ceremony-2019" element={<RedirectLinks/>} />
            <Route path="/the-league/habib-university-campus-drive-2020" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-nust-campus-drive" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-2018-orientation-ceremony" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-iba-campus-drive" element={<RedirectLinks/>} />
            <Route path="/the-league/what-do-we-look-for" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-lse-campus-drive" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/innovation-and-financial-inclusion" element={<RedirectLinks/>} />
            <Route path="/the-league/summer-internship-2017-orientation-ceremony" element={<RedirectLinks/>} />
            <Route path="/the-league/the-league-orientation-ceremony-2018" element={<RedirectLinks/>} />
            <Route path="/the-league/ufaqs/human-resources-and-organizational-development" element={<RedirectLinks/>} />
            <Route path="/the-league/agp-what-do-we-look-for" element={<RedirectLinks/>} />
            <Route path="/applynow" element={<RedirectLinks/>} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// reportWebVitals();
