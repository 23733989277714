import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionislamicbanking = () => {
  return (
    <>
<Helmet>
<title>Islamic Banking | HBL People</title>
<meta
name="description"
content="Be a part of our team of passionate and talented Islamic Banking professionals."
/>
</Helmet>
<section className="innerpagebanner islamic-banking">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Islamic Banking</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Be a part of our team of passionate and talented Islamic Banking professionals.</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages islamic-banking">
  <div className="container d-flex flex-wrap gap-3">
    <p>Islamic banking has risen to become a key player in the financial landscape of Pakistan. With its ethical principles and social responsibility, it has attracted millions of customers and achieved impressive growth in the past two decades. Islamic banking is not just a niche market anymore; it is a vital force that can drive the economic development and inclusion of the country. By reaching out to the unserved and underserved sectors of the society, Islamic banking is more than just banking; it is a vision of a better future.</p>
    <p>HBL Islamic started its operations in early 2000s. It is a trusted partner that understands its customers’ values and needs. Whether its savings, investing, or borrowing, HBL Islamic has a range of Shariah-compliant financial solutions that suit all lifestyle and goals. The customers can enjoy top-notch customer products and services that are governed by our Shariah Board, which consists of eminent Islamic scholars from Pakistan. With HBL Islamic, you can achieve financial peace of mind while staying true to your faith.</p>
    <p>HBL Islamic is one of the largest players in the Islamic Banking industry, with a market share of 7% in Pakistan. We have a wide network of over 300 branches and 500 Islamic windows across the country, reaching out to customers from all walks of life. We offer diverse and innovative Shariah-compliant financial solutions for businesses and individuals, catering to their specific needs and requirements. Whether it is corporate, commercial, retail, consumer, or treasury, HBL Islamic has the expertise and experience to deliver excellence and value.</p>
    <p>At HBL Islamic, we believe that everyone should have an option to choose from a Shariah-compliant banking solution that meets their unique needs and preferences. That is why we have designed a range of Current and Savings products for different customer segments. Whether it’s a woman who wants to achieve financial independence and security with NISA, a non-resident Pakistani who wants to stay connected to your homeland with Roshan Digital Account, a minor who wants to start saving early with Uraan, or a senior citizen who wants to enjoy the benefits of your golden years with Rutba, HBL Islamic has the perfect product for you.</p>
    <p>HBL Islamic is at the forefront of digital banking, offering convenient and secure Shariah-compliant solutions for its customers who can access their account anytime, anywhere with our dedicated Islamic mobile app and internet banking. They can also perform various transactions such as paying bills, transferring funds, and more with just a few clicks. HBL Islamic also introduced Konnect Islamic, a branchless banking Shariah-compliant solution to the unbanked population. Moreover, existing customers can avail digital car financing from HBL Islamic app with an end-to-end process.</p>
    <p>HBL Islamic is committed to spreading Islamic Banking knowledge and awareness among its staff and customers. As the State Bank of Pakistan has mandated Banks to adopt Islamic Banking, HBL Islamic has taken the lead in providing comprehensive training programs for its staff. These programs are conducted in collaboration with the regulator and other financial institutions, and they cover various aspects of Islamic Banking. By participating in these programs, the staff can enhance their skills and knowledge, as well as advance their career prospects.</p>
    <p>The State Bank of Pakistan has a bold vision for the future of Islamic banking in the country. By 2025, it aims to make Islamic banking a major force in the financial sector, with a significant share in assets, deposits, branches, and financing for SMEs and agriculture. This is a fantastic opportunity for Islamic banking to create value for the economy and the society. HBL Islamic is proud to be part of this journey and invites you to join our team of enthusiastic and talented professionals. Together, we can grow and make a difference with Islamic banking.</p>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionislamicbanking