import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionchinabusiness = () => {
  return (
    <>
    <Helmet>
<title>China Business | HBL People</title>
<meta
name="description"
content="Our Second Home"
/>
</Helmet>
<section className="innerpagebanner china-business">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">China Business</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Our Second Home</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages china-business">
  <div className="container d-flex flex-wrap gap-3">
    <p>In 2013, HBL established a dedicated team of seasoned bankers in Pakistan catering to Chinese clients’ needs. Over the years this unit expanded to include teams all over Pakistan, reaching across all functions and products offered by HBL.  </p>

<p>In 2016, we became the first Pakistani bank to receive a branch license in China, we now have two branches in China that serve as the main engine for origination and execution of Chinese transactions, facilitating greater intra-network flows. </p> 

<p>The Bank’s successful China coverage model now covers UAE, Sri Lanka, Bangladesh and the Maldives. In 2022, this model was expanded to East Africa in partnership with our affiliate - Diamond Trust Bank. In Pakistan, HBL remains the leading bank for China business. HBL’s significance to Pak-China relations was further highlighted when HBL President had the honor of visiting China as part of the delegation of the Prime Minister of Pakistan.</p>

<p>We are one of only 3 banks in MENA and South Asia to offer end-to-end RMB intermediation, extending liquidity management and salary remittance services to Chinese clients and expatriate employees. </p> 

<p>We intend to continue leveraging our unique position and become the flagship bank for international business over the next few years.</p>

  </div>
</section>

    <Testimonials />
    <Functionfilter />
        </>
  )
}

export default Functionchinabusiness
