import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionglobalcompliance = () => {
  return (
    <>
<Helmet>
<title>Global Compliance | HBL People</title>
<meta
name="description"
content="From good to great"
/>
</Helmet>
<section className="innerpagebanner global-compliance">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Global Compliance</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">From good to great</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages global-compliance">
  <div className="container d-flex flex-wrap gap-3">
    <p data-sr="enter top over 0.3s and move 65px wait 0.3s">Global Compliance &amp; Conduct (GCC), as the second line of defence, is committed to maintaining the highest Compliance standards benchmarked to global best practices in order to meet increasing regulatory expectations. We are responsible to strengthen the compliance environment and manage compliance risk across the institution by enabling the Bank to understand, implement and comply with applicable laws, regulations, guidelines and the Bank’s internal policies and procedures. </p>
    <p data-sr="enter top over 0.3s and move 65px wait 0.6s">Global Compliance &amp; Conduct is strategically positioned to enhance compliance monitoring, digital enablement &amp; innovation and integration of ethics &amp; conduct in order to stay relevant, sustainable and seamlessly connect with its partners - all HBL businesses and functions, whilst enabling the necessary touchpoints with Regulators. We strive to build the Compliance function as a center of excellence, domestically and across HBL’s international network.</p>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionglobalcompliance
