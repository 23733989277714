import React, { useEffect } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";


const Layout = ({ children }) => {

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.clear();
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Helmet>
        <script>
          window.sr = new scrollReveal();
        </script>
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
