import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionbranchbanking = () => {
  return (
    <>
     <Helmet>
     <title>Branch Banking | HBL People</title>
<meta
name="description"
content="Your Catalyst for success"
/>

            </Helmet>
      <section className="innerpagebanner branch-banking">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Branch Banking</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Your Catalyst for success</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages branch-banking">
  <div className="container d-flex flex-wrap gap-3">
    <p>Welcome to HBL's Branch Banking Group, the powerhouse of our Bank, renowned for its expansive reach across Pakistan with over 1,400 branches and 2,200 ATMs. We are one of the core functions driving Business. Within this dynamic group, we offer seven key functions, each playing a crucial role in our success: </p>
    <p>Retail Products, Analytics and Process Engineering, Governance, National Sales, Prestige Banking, Distribution (Sales), and Business Planning and Strategy.</p>
    <div>
      <h4>Retail Products </h4>
      <p>As a member of the Retail Products Team, you will find yourself at the forefront of bringing visionary products and propositions to life, from their conceptualization to their triumphant entry into the market, and beyond.  By joining us, you will not only develop invaluable skills and expertise in your chosen field, but also immerse yourself in a dynamic and innovative work environment. Here, you will collaborate with industry experts, exchange ideas, and learn from the brightest minds in the business. </p>
    </div>
    <p>At HBL, Retail Products has 5 sub-functions including HBL Prestige, Premium Strategy, Wealth Management, Women Marketing Program & Non-Resident Pakistani Banking, and Liability Products.</p>
    <div>
      <h4>HBL Prestige</h4>
      <p>HBL Prestige is a value proposition designed to provide HBL’s High Net Worth clients with a world class banking experience. With an expanding footprint nationwide, at Prestige, you will learn to navigate the intricate world of high-net-worth individuals, enhance your skills in relationship management and tailored financial solutions and wealth preservation, backed by accelerated career growth. </p>
    </div>
    <div>
      <h4>Liability Products, Wealth Management and Women Marketing Program & Non-Resident Pakistani Banking</h4>
      <p>The Liability Product team plays a pivotal role in driving growth, ensuring compliance, and maximizing profitability through the development and management of deposit products, market research, and performance analysis. Furthermore, the Wealth Management team empowers clients with a diverse range of Savings, Protection, and Investment options, equipping them with the tools to strengthen their financial well-being and unlock the full potential for maximum returns. Additionally, HBL's unwavering commitment to financial inclusion and empowerment is exemplified through The Women Market Program team and its innovative product suite Nisa, which tirelessly strives to integrate the vast unbanked female population into the banking system, fostering a transformative environment of inclusivity and empowerment.</p>
    </div>
    <p>By embracing the opportunity to be part of these exceptional teams, you will be empowered to ignite transformative change, uplift clients, and wield your expertise to shape the future of finance. </p>
    <div>
      <h4>Analytics and Process Engineering</h4>
      <p>The Analytics and Process Engineering team, fosters data-led business decisions, identifies opportunities for growth in the distribution, manages performance management, and leverages state-of-the-art in-house built technology for their processes. Our Process Improvement function working on improving existing policies, processes, and customer experiences. As part of this team, you will be able to access our advanced analytics programs that utilize artificial intelligence, spatial analytics, power BI tools, and propensity modeling tools, to enhance sales management initiatives.</p>
    </div>
    <div>
      <h4>National Sales</h4>
      <p>As a part of our National Sales function, you will be dedicated to driving business development and sales mobilization strategies including but not limited to Business Sales Executive, a dedicated salesforce for Deposits. Moreover, this function fosters all digital initiatives for the Branch Banking group. A critical sub-function of this team is: Network Optimization through which you will be able to access, manage, and optimize the entire network's footprint of Branches, ATMs, CDMs, & FFMs Distribution (Sales) </p>
    </div>
    <p>The Distribution network encompasses 1,400+ branches, 130 areas, 19 regions, and 6 distributions, with each cluster managed by a Distribution Head. Our teams also consist of Regional Distribution Heads, Regional Business Managers, Analytics Officers, and Business Development Managers specializing in Wealth Management. As a member of this function, you'll be responsible for delivering non-business and business objectives of the Bank, along with driving sales for Deposit, Wealth, Consumer, Trade, SME, and Agriculture Banking products.</p>
    <div>
      <h4>Governance</h4>
      <p>Governance leads the compliance, risk, control, and regulatory initiatives of the Branch Banking group and ensures that business activities are conducted in compliance with HBL and State Bank’s regulations and policies. They facilitate representation and implementation of all action items from Board and Management Committees. As a member of this team, you will be responsible for ensuring that all business activities are carried out in a compliant manner by establishing the right control environment. With a focus on supporting business teams, you will help to interpret the regulatory and internal control environment for products, processes, and procedures. </p>
    </div>
    <div>
      <h4>Business Planning and Strategy</h4>
      <p>The Business Planning and Strategy team works closely with the Head of Branch Banking Group to design, implement, and monitor strategies and plans across the various functions of the Group. This team leverages on business analytics to drive comprehensive business decisions and produce network-wide impact. As part of this team, you will have the unique opportunity to make a tangible impact on the business and other functions through a combination of analytical expertise and meaningful stakeholder engagement. We collaborate with stakeholders across the Bank to enhance cross-functional synergies amongst other groups of the Bank, lead and implement enterprise level strategic projects, and ensuring efficient communication of internal business objectives. We are committed to fostering a culture of learning and empowering team members with resources and support to achieve their full potential. With a focus on change and stakeholder collaboration, you will be at the forefront of driving Branch banking’s growth and success, influencing the Bank's overarching strategic decisions. </p>
    </div>

    <p>Join us and become a part of HBL's Branch Banking Group, where you will enhance your skills, foster innovation, and shape the future of banking in Pakistan!</p>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionbranchbanking
