import React, { useEffect } from "react";
import "magnific-popup/dist/magnific-popup.css";
import "magnific-popup/dist/jquery.magnific-popup";
import $ from "jquery";

const Testimonials = () => {
  useEffect(() => {
    const mySwiper = new window.Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    // $(".youtubepopup").magnificPopup({
    //   type: "iframe",
    // });
    $('.youtubepopup').magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          wistia: {
            index: 'wistia.com',
            id: function(url) {        
                var m = url.match(/^.+wistia.com\/(medias)\/([^_]+)[^#]*(#medias=([^_&]+))?/);
                if (m !== null) {
                    if(m[4] !== undefined) {
                        return m[4];
                    }
                    return m[2];
                }
                return null;
            },
            src: '//fast.wistia.net/embed/iframe/%id%?autoPlay=0'
          }
        }
      }
    });
  }, []);
  return (
    <>
      <section
        className="home-three"
        data-sr="enter top over 0.3s and move 65px wait 0.4s"
      >
        <div className="container">
          <div className="text-center">
            <h3 className="web">HBL STORIES</h3>
            <h2 className="web"> PUTTING PEOPLE FIRST </h2>
          </div>
        </div>
        <div className="swiper mySwiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="container">
                <div className="row align-items-center my-5 gx-xl-1">
                  <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
                    <p>
                      Being a part of HBL's Innovation & Financial Inclusion
                      group has enabled me to carry its vision forward in the
                      realm of digital transformation. HBL’s dedication to
                      embracing cutting-edge technologies has revolutionized the
                      way we serve our customers. It has aided me in committing
                      to fostering a culture of collaboration, excellence, and
                      progressiveness that will drive growth, innovation, and
                      most importantly customer satisfaction.
                    </p>
                  </div>
                  <div className="col-lg-12 col-xl-4 order-1 order-xl-2">
                    <img
                      src="images/testi-daniyal.jpg"
                      alt=""
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                  <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
                    <h4>
                      Daniyal Naveed
                      <span>
                        Senior Manager Digital Products Pricing and Segments
                        <br />
                        Innovation and Financial Inclusion
                      </span>
                    </h4>
                    <a
                      href="https://hblpeople.wistia.com/medias/fqhc8cyka2"
                      className="link youtubepopup"
                    >
                      
                      <img src="images/testimonial-vidicon.png" alt="" /> Watch
                      Daniyal’s Interview
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="container">
                <div className="row align-items-center my-5 gx-xl-1">
                  <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
                    <p>
                      When I look back at my journey at HBL, I feel humbled and
                      honoured to be associated with an organization that is the
                      epitome of professionalism and commitment to customer
                      service. I have seen myself developing, both personally
                      and professionally, in a conducive work environment. HBL
                      nurtured my penchant for excellence in customer service
                      and helped me attain unprecedented levels of service in my
                      department.
                    </p>
                  </div>
                  <div className="col-lg-12 col-xl-4 order-1 order-xl-2">
                    <img
                      src="images/testi-kamran.jpg"
                      alt=""
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                  <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
                    <h4>
                      Muhammad Kamran Siddiqui
                      <span>
                        Head Contact Center and RDA Services <br />
                        Content Design <br />
                        Operations Services
                      </span>
                    </h4>
                    <a
                      href="https://hblpeople.wistia.com/medias/t53a7p2xfa"
                      className="link youtubepopup"
                    >
                      
                      <img src="images/testimonial-vidicon.png" alt="" /> Watch
                      Kamran’s Interview
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="container">
                <div className="row align-items-center my-5 gx-xl-1">
                  <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
                    <p>
                      I embarked on a banking career with great enthusiasm 14
                      years back, but my journey with HBL has proven to be a
                      rewarding one beyond my expectations. The dynamic nature
                      of the bank, coupled with the opportunity to work closely
                      with diverse clients, has honed my skills in customer
                      service, financial analysis, and risk management.
                    </p>
                  </div>
                  <div className="col-lg-12 col-xl-4 order-1 order-xl-2">
                    <img
                      src="images/testi-batool.jpg"
                      alt=""
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                  <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
                    <h4>
                      Batool Imran
                      <span>
                        Branch Manager
                        <br />
                        Branch Banking
                      </span>
                    </h4>
                    <a
                      href="https://hblpeople.wistia.com/medias/bj88yaknwr"
                      className="link youtubepopup"
                    >
                      
                      <img src="images/testimonial-vidicon.png" alt="" /> Watch
                      Batool’s Interview
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="container">
                <div className="row align-items-center my-5 gx-xl-1">
                  <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
                    <p>
                      I started my working career with HBL in Feb of 2013 and
                      recently completed a decade! Being associated with one of
                      the biggest organizations in Pakistan has been a
                      life-changing milestone for me. HBL has undoubtedly
                      provided me with the right blend of environment, learning
                      experiences and people that have played a key role in
                      contributing to where I am today as a professional and how
                      much I have grown on a personal level.
                    </p>
                  </div>
                  <div className="col-lg-12 col-xl-4 order-1 order-xl-2">
                    <img
                      src="images/testi-quratulain.png"
                      alt=""
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                  <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
                    <h4>
                      Qurat Ul Ain Ahsan
                      <span>
                        Senior Manager Talent Acquisition
                        <br />
                        Human Resources
                      </span>
                    </h4>
                    <a
                      href="https://hblpeople.wistia.com/medias/l6ba3xw8pl"
                      className="link youtubepopup"
                    >
                      
                      <img src="images/testimonial-vidicon.png" alt="" /> Watch
                      Qurat Ul Ain’s Interview
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="container">
                <div className="row align-items-center my-5 gx-xl-1">
                  <div className="col-lg-6 col-xl-4 order-2 order-xl-1">
                    <p>
                      I have the privilege of working in the Market risk &
                      treasury middle office at HBL, and I can confidently say
                      that it has been one of the best experiences of my
                      professional career. The organization has a culture of
                      excellence, and everyone works together to achieve the
                      bank’s goals.The leadership team is committed to the
                      growth and development of its employees and provides the
                      necessary resources to help them achieve their full
                      potential.
                    </p>
                  </div>
                  <div className="col-lg-12 col-xl-4 order-1 order-xl-2">
                    <img
                      src="images/testi-razaabbas.png"
                      alt=""
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                  <div className="col-lg-6 col-xl-4 order-3 order-xl-3">
                    <h4>
                      Syed Muhammad Raza Abbas
                      <span>
                        Risk Analyst
                        <br />
                        Risk Management
                      </span>
                    </h4>
                    <a
                      href="https://hblpeople.wistia.com/medias/q88qjzd8ar"
                      className="link youtubepopup"
                    >
                      
                      <img src="images/testimonial-vidicon.png" alt="" /> Watch
                      Raza’s Interview
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
