import React, { useState, useEffect } from 'react';

const Functionfilter = () => {

    const strings = ["Branch Banking", "Risk Management", "Remedial Assets and Structured Credits", "Global Operations"];
    const [currentStringIndex, setCurrentStringIndex] = useState(0);
    const [currentText, setCurrentText] = useState('');
    const [isTyping, setIsTyping] = useState(true);
  
    const typingSpeed = 100;
    const deleteSpeed = 40;
    const isLoop = true;
    const isPlaceholder = true;
  
    //-------------------------

    useEffect(() => {
        const typing = () => {
          if (isPlaceholder) {
            document.getElementById("auto").placeholder = strings[currentStringIndex].substring(0, currentText.length);
          } else {
            document.getElementById("auto").placeholder = strings[currentStringIndex].substring(0, currentText.length);
          }
    
          if (currentText.length === strings[currentStringIndex].length) {
            setTimeout(() => {
              setIsTyping(false);
              setCurrentText('');
    
              if (isLoop || currentStringIndex < strings.length - 1) {
                setTimeout(() => {
                  setIsTyping(true);
                  setCurrentStringIndex((prevIndex) => (prevIndex + 1) % strings.length);
                }, deleteSpeed);
              }
            }, typingSpeed);
          } else {
            setTimeout(() => {
              setCurrentText((prevText) => strings[currentStringIndex].substring(0, prevText.length + 1));
            }, typingSpeed);
          }
        };
    
        if (isTyping) {
          typing();
        }
        // eslint-disable-next-line
      }, [currentText, currentStringIndex, isLoop, isPlaceholder, isTyping]);
//------------------

const suggestions = [
    {
      label: 'Branch Banking',
      url: 'function-branch-banking'
    }, {
      label: 'Branch Operations',
      url: 'function-branch-operations'
    }, {
      label: 'Corporate and Investment Banking',
      url: 'function-corporate-and-investment-banking'
    }, {
      label: 'Consumer Agriculture',
      url: 'function-consumer-agriculture'
    }, {
      label: 'Islamic Banking',
      url: 'function-islamic-banking'
    }, {
      label: 'China Business',
      url: 'function-china-business'
    }, {
      label: 'Innovation and Financial Inclusion',
      url: 'function-innovation-financial-inclusion'
    }, {
      label: 'International Banking',
      url: 'function-international-banking'
    }, {
      label: 'Finance',
      url: 'function-finance'
    }, {
      label: 'Global Compliance',
      url: 'function-global-compliance'
    }, {
      label: 'Human Resources',
      url: 'function-human-resources'
    }, {
      label: 'Information Technology',
      url: 'function-information-technology'
    }, {
      label: 'Internal Audit',
      url: 'function-internal-audit'
    }, {
      label: 'Corporate Secretariat',
      url: 'function-corporate-secretariat'
    }, {
      label: 'Information Security',
      url: 'function-information-security'
    }, {
      label: 'Marketing and Corporate Affairs',
      url: 'function-marketing-corporate-affairs'
    }, {
      label: 'Remedial Assets and Structured Credits',
      url: 'function-remedial-structured-credit'
    }, {
      label: 'Risk Management',
      url: 'function-risk-management'
    }, {
      label: 'Service Quality',
      url: 'function-service-quality'
    }, {
      label: 'Legal Affairs',
      url: 'function-legal-affairs'
    }, {
      label: 'Treasury Global Markets',
      url: 'function-treasury-global-markets'
    }, {
      label: 'Development Finance ',
      url: 'function-development-finance'
    }, {
      label: 'Operation Services ',
      url: 'function-operation-services'
    }, {
      label: 'Corporate Strategy',
      url: 'function-corporate-strategy'
    }
  ];

  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Filter suggestions based on user input
    const filtered = suggestions.filter(suggestion =>
      suggestion.label.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filtered);
  };

  const handleSuggestionClick = (suggestion) => {
    // Set the selected suggestion and clear the input
    setSelectedSuggestion(suggestion);
    // setInputValue('');
    setInputValue(suggestion.label);
    setFilteredSuggestions([]);
  };

  const handleButtonClick = () => {
    // Handle button click, for example, navigate to the selected URL
    if (selectedSuggestion) {
      window.location.href = selectedSuggestion.url;
    }
  };

  return (
    <div>

<section className="home-five" data-sr="enter top over .5s and move 65px wait 0s">
  <div className="container">
    <div className="text-center">
      <h3 className="web">FIND YOUR TEAM</h3>
      <h2 className="web"> Discover a whole <br/>
        New World of Opportunities </h2>
      <h4>Search for your team of interest</h4>
    </div>
    <div className="row justify-content-center my-4">
      <div className="col-md-6 col-lg-7">
        <div className="row">
          <div className="col-lg-7">
            <p className="py-2">Start typing below and we will suggest a match for you:</p>
          </div>
          <div className="col-lg-5">
          
      <div className="sform">
        <input type="text" id="auto" placeholder="" value={inputValue === null ? 'null' : inputValue} className="sfield" onChange={handleInputChange}  />
        {selectedSuggestion && (
        <div id="outputcontent">
          <a className="cust-but" onClick={handleButtonClick}>Click</a>
        </div>
        )}
        {/* </div> */}
       {filteredSuggestions.length > 0 && (
        <ul className='suggfilt'>
          {filteredSuggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
      </div>

      {/* {selectedSuggestion && (
        <div>
        <p>Selected: {selectedSuggestion.label}</p>
          <button className='cust-but' onClick={handleButtonClick}>Go to {selectedSuggestion.label}</button>
        </div>
      )} */}

      </div>
      </div>
      </div>
    </div>
    <div class="text-center mt-5"> 
    <a href="function" class="functionlink">EXPLORE ALL FUNCTIONS</a> 
    </div>
  </div>
</section>
    </div>
  )
}

export default Functionfilter