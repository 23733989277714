import React from 'react'
import Testimonials from '../../components/Testimonials'
import Functionfilter from '../../components/Functionfilter'
import {Helmet} from "react-helmet";

const Functionoperationservices = () => {
  return (
    <>
<Helmet>
<title>Operations Services | HBL People</title>
<meta
name="description"
content="Providing support to frontline culminating in quality customer service"
/>
</Helmet>
<section className="innerpagebanner operation-services">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">Operations Services</h1>
      <h2 data-sr="enter top over 0.3s and move 65px wait 0.6s">Providing support to frontline culminating in quality customer service</h2>
      <a href="https://hdcs.fa.ap1.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_12005" target="_blank" rel="noreferrer noopener">EXPLORE OPPORTUNITIES AT HBL</a> </div>
  </div>
</section>
<section className="innersection functionpages operation-services">
  <div className="container d-flex flex-wrap gap-3">
    <p> ‘Operations Services’ provides foundational support to HBL’s dynamic Operating Model. The broad spectrum of areas it covers, impacts domains across the bank, and is vital to seamless delivery of Products &amp; Services to the customers. </p>
    <p>Through the extensive spread of its scope, it renders relentless support to the frontline Business teams by administering Business processes and procedures, some of which run round the clock, culminating in quality customer service.</p>
    <p>By offering a wide range of activities and depth of information, ‘Operation Services' presents huge opportunities to individuals who are looking to enhance their core banking skills &amp; knowledge. </p>
    <p>The areas that collectively fall under the extensive umbrella of ‘Operation Services’ are:</p>
    <div>
      <h4>Digital Customer Experience</h4>
      <p>Digital Customer Experience comprises of several units; Digital Channel Development, Contact Center, Inbound, IVR, Voice, Emails, Webchat, RDA Services, Outbound, Social Media Services to provide exemplary services to our customers. As a department, we strive to continuously provide the best-in-class services to our customers through different mediums and ensure that customers avail the best services. </p>
    </div>
    <div>
      <h4>Trade Operations</h4>
      <p>‘Trade operations’ is responsible for efficient processing of all trade related transactions of bank’s valued customers i.e., Exports, Imports. Letters of credit and guarantees, while also ensuring that the regulatory requirements are strictly adhered to at all times. Trade operations provides critical support to the trade business by retaining the customer base through delivery of swift and error-free services leading to heightened customer satisfaction. 2021-Number of Transactions 72090, Volumes USD 12.7 billion</p>
    </div>
    <div>
      <h4>Payment Services</h4>
      <p>‘Payment Services’ provides a unified and centralized platform for processing of payments pertaining to Salaries, Pensions, Remittances, Clearing, Consumer Loans, Debit &amp; Credit Cards, including Treasury transactions initiated by Treasury Front Office. It provides round the clock services by ensuring that the ATMs across the Bank’s network always remain fully functional. Several wings of payment services operate in harmony to create an enhanced service experience for internal and external customers. These include Consumer Loans disbursements and payments, end to end Remittance processes, Alternate Delivery Channels (ADC) for Branchless Banking, I &amp; FI Business, individual customers, Merchant and Agent Onboarding, Cash Management Functions, Supply Chain Financing &amp; SBP Refinancing related payments and activities.
        2021 -Number of Transactions 265.6 million, Volumes Rs26.6 trillion</p>
    </div>
    <div>
      <h4>International Operations</h4>
      <p>‘International Operations’ acts as a focal point for the International Branch Network in matters relating to operations. It keeps vigilance on control issues and creates a bridge between international locations and the Head Office, while monitoring key operational aspects through alignment of policies and procedures. It ensures compliance of International Operating Model with the respective regulatory environment while it also aligns international policies with HBL’s global policies.</p>
    </div>
    <div>
      <h4>Business Policies &amp; Procedures</h4>
      <p>‘Business Policies &amp; Procedures’ renders support to units in developing Policies, Procedures, SOPs, Circulars and Product Programs. It collaborates with the ‘Operations Services’ functions to assist in addressing audit observations and identifies opportunities for achieving cost efficiency in processes of ‘Operation Services’.</p>
    </div>
    <div>
      <h4>Shared Services</h4>
      <p>An integral part of Operations Services, ‘Shared Services’ provides efficient support to entire network of HBL by managing the evolving needs of stakeholders with optimal utilization of resources.  ‘Shared Services’ assists the Bank to implement cost effective, sustainable, transparent &amp; progressive solutions for Bank’s Procurement, Properties, Construction / Renovation, Business Continuity Planning, Security and Administrative affairs, ultimately contributing towards profitability, efficiency &amp; enhanced Brand Image of HBL.</p>
    </div>
  </div>
</section>
<Testimonials />
<Functionfilter />
    </>
  )
}

export default Functionoperationservices