import React,{useEffect} from 'react'
import $ from "jquery";
import { Helmet } from "react-helmet";

const Function = () => {
    useEffect(() => {
    
        $("#myInput").on("keyup", function() {
          var value = $(this).val().toLowerCase();
          $(".grid a").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
          });
        });

        let singleslider = new window.Swiper(".singleSwiper", {
          slidesPerView: 1,
          spaceBetween: 1,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
 
      }, []);
  return (
    <>
    <Helmet>
<title>Explore Our Functions | HBL People</title>
<meta
name="description"
content="Discover a whole new world of opportunities"
/>
</Helmet>
      <section className="innerpagebanner function">
  <div className="container">
    <div className="in">
      <h1 data-sr="enter top over 0.3s and move 65px wait 0.3s">EXPLORE OUR FUNCTIONS</h1>
      <h2 data-sr="enter bottom over 0.3s and move 65px wait 0.6s">DISCOVER A WHOLE NEW WORLD OF OPPORTUNITIES</h2>
    </div>
  </div>
</section>
<section className="innersection function">
  <div className="container">
    <div className="text-center mx-auto" style={{maxWidth: '800px'}}>
      <h3 className="web" data-sr="enter top over 0.3s and move 65px wait 0.3s">What do we Do?</h3>
      <h2 className="web" data-sr="enter bottom over 0.3s and move 65px wait 0.6s">OUR BUSINESS IS EXCITING
        YET MULTIFACETED</h2>
    </div>
    <div className="text-center mx-auto" style={{maxWidth: '1030px'}}>
      <p data-sr="enter top over 0.3s and move 65px wait 0.3s">Choosing the right function can be tough, and the truth is there are no easy answers, but there are lots of great questions. Let us help you figure out what type of role, business and industry is right for you.</p>
      <div className="mx-auto" style={{maxWidth: '700px'}}>
        <input type="text" id="myInput" placeholder="Search your functions..." data-sr="enter top over 0.3s and move 65px wait 0.3s"/>
      </div>
    </div>
    <div className="grid" data-sr="enter top over 0.3s and move 65px wait 0.3s"> <a href="function-branch-banking">
      <div> <img src="images/function/icon-02.png" alt="functions icon"/>
        <h4>Branch Banking</h4>
      </div>
      </a> <a href="function-branch-operations">
      <div> <img src="images/function/icon-03.png" alt="functions icon"/>
        <h4>BRANCH OPERATIONS AND ACCOUNT SERVICES</h4>
      </div>
      </a> <a href="function-china-business">
      <div> <img src="images/function/icon-04.png" alt="functions icon"/>
        <h4>China Business</h4>
      </div>
      </a> <a href="function-consumer-agriculture">
      <div> <img src="images/function/icon-05.png" alt="functions icon"/>
        <h4>CONSUMER AGRICULTURE AND SME BANKING</h4>
      </div>
      </a> <a href="function-corporate-and-investment-banking">
      <div> <img src="images/function/icon-06.png" alt="functions icon"/>
        <h4>CORPORATE COMMERCIAL AND INVESTMENT BANKING</h4>
      </div>
      </a> <a href="function-corporate-secretariat">
      <div> <img src="images/function/icon-01.png" alt="functions icon"/>
        <h4>Corporate Secretariat</h4>
      </div>
      </a> <a href="function-corporate-strategy">
      <div> <img src="images/function/icon-07.png" alt="functions icon"/>
        <h4>Corporate Strategy</h4>
      </div>
      </a> <a href="function-development-finance">
      <div> <img src="images/function/icon-08.png" alt="functions icon"/>
        <h4>development finance</h4>
      </div>
      </a> <a href="function-finance">
      <div> <img src="images/function/icon-09.png" alt="functions icon"/>
        <h4>finance</h4>
      </div>
      </a> <a href="function-global-compliance">
      <div> <img src="images/function/icon-10.png" alt="functions icon"/>
        <h4>global compliance</h4>
      </div>
      </a> <a href="function-human-resources">
      <div> <img src="images/function/icon-11.png" alt="functions icon"/>
        <h4>human resources</h4>
      </div>
      </a> <a href="function-information-security">
      <div> <img src="images/function/icon-12.png" alt="functions icon"/>
        <h4>information security</h4>
      </div>
      </a> <a href="function-information-technology">
      <div> <img src="images/function/icon-13.png" alt="functions icon"/>
        <h4>information technology</h4>
      </div>
      </a> <a href="function-innovation-financial-inclusion">
      <div> <img src="images/function/icon-14.png" alt="functions icon"/>
        <h4>INNOVATION AND
          FINANCIAL INCLUSION</h4>
      </div>
      </a> <a href="function-internal-audit">
      <div> <img src="images/function/icon-15.png" alt="functions icon"/>
        <h4>internal audit</h4>
      </div>
      </a> <a href="function-international-banking">
      <div> <img src="images/function/icon-16.png" alt="functions icon"/>
        <h4>international banking</h4>
      </div>
      </a> <a href="function-islamic-banking">
      <div> <img src="images/function/icon-17.png" alt="functions icon"/>
        <h4>islamic banking</h4>
      </div>
      </a> <a href="function-legal-affairs">
      <div> <img src="images/function/icon-18.png" alt="functions icon"/>
        <h4>legal affairs</h4>
      </div>
      </a> <a href="function-marketing-corporate-affairs">
      <div> <img src="images/function/icon-19.png" alt="functions icon"/>
        <h4>MARKETING AND CORPORATE AFFAIRS</h4>
      </div>
      </a> <a href="function-operation-services">
      <div> <img src="images/function/icon-20.png" alt="functions icon"/>
        <h4>operation services</h4>
      </div>
      </a> <a href="function-remedial-structured-credit">
      <div> <img src="images/function/icon-21.png" alt="functions icon"/>
        <h4>REMEDIAL ASSETS AND STRUCTURED CREDIT</h4>
      </div>
      </a> <a href="function-risk-management">
      <div> <img src="images/function/icon-22.png" alt="functions icon"/>
        <h4>risk management</h4>
      </div>
      </a> <a href="function-service-quality">
      <div> <img src="images/function/icon-23.png" alt="functions icon"/>
        <h4>service quality</h4>
      </div>
      </a> <a href="function-treasury-global-markets">
      <div> <img src="images/function/icon-24.png" alt="functions icon"/>
        <h4>TREASURY AND GLOBAL MARKETS</h4>
      </div>
      </a> </div>
  </div>
</section>
<section className="innersection function">
  <div className="container">
    <div className="text-center">
      <h3 className="web">OUR FEATURED FUNCTIONS</h3>
      <h2 className="web">EXPLORE YOUR RIGHT FIT</h2>
    </div>
  </div>
</section>
<div className="swiper singleSwiper">
  <div className="swiper-wrapper">
    <div className="swiper-slide">
      <section className="innersection function section2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3>TREASURY & GLOBAL MARKETS</h3>
              <p className="white mt-3"> HBLTreasury & Global Markets (TGM) is a core part of a bank’s structure and of Pakistan’s financial landscape. The Bank is an authorized dealer in foreign exchange, one of the Primary Dealers nominated for the sale & distribution of government debt and an authorized dealer for Derivatives. It comprises of units dealing in matters related to ALM & Structured Products, Sales Desk, Equities and Research division, Global markets trading , business support while managing International and Islamic Treasury separately. </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="swiper-slide">
      <section className="innersection function section2 corporatecommercial">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3>Corporate Commercial & Investment Banking</h3>
              <p className="white mt-3"> HBL’s Corporate Commercial & Investment Banking Group services clients across various segments for all their banking needs. We act as a one window solution for companies through a collaborative model with Treasury, Transaction Banking, Investment Banking, along with offering access to HBL’s suite of consumer and retail banking products to the management and employees of these companies and institutions. </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="swiper-slide">
      <section className="innersection function section2 branchbanking">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3>Branch Banking</h3>
              <p className="white mt-3"> HBL Branch Banking group consists of multiple divisions which leverage HBL’s 1,700+ branch network catering to needs of all kind of client be it Prestige or Conventional because every client deserves an immaculate experience. The divisions include Distribution (19 regions), Sales Management, Retail Products and Governance. Branch Banking primarily represents the ‘business / sales’ wing of the branch network.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="swiper-slide">
      <section className="innersection function section2 technology">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3>Technology (Information Technology, Information Security, Innovation & Financial Inclusion)</h3>
              <p className="white mt-3"> HBL’s vision is to embed technology within the very fabric of the Bank. We see technology as a force multiplier and enabler and recognize it as a crucial, but not the only, component of our drive towards increasing revenues, reducing costs and enhancing customer experience. We believe that technology enabled services with empowered employees that will allow the full potential of the bank to be unleashed be it through our tech functions including Information Technology – creating harmony, Information Security – our line of defense and Innovation & Financial Inclusion – our gateway to digital transformation. </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div className="swiper-button-next"></div>
  <div className="swiper-button-prev"></div>
</div>
<section className="innersection function fulldivlink">
  <div className="container">
    <div className="text-center">
      <h3 className="web">WAYS TO JOIN US</h3>
      <h2 className="web">A LOT MORE FOR YOU TO EXPLORE</h2>
    </div>
    <div className="row">
      <div className="col-md-6 col-lg-4">
        <div className="in"> <a href="experienced-professionals" className="fulllink">
          <div className="imgdiv"><img src="images/function/experianced.jpg" alt=""/></div>
          <h4>Experienced Professionals</h4>
          </a> <a href="experienced-professionals" className="link"><img src="images/arrowgreen.png" alt="arrow"/> Explore opportinites at HBL</a> </div>
      </div>
      <div className="col-md-6 col-lg-4">
        <div className="in"> <a href="https://theleague.hblpeople.com" className="fulllink">
          <div className="imgdiv"><img src="images/function/the-league.jpg" alt=""/></div>
          <h4>The League Programme</h4>
          </a> <a href="https://theleague.hblpeople.com" className="link"><img src="images/arrowgreen.png" alt="arrow"/> <span>Our flagship Management Trainee &amp; Accelerate Graduate Programme</span></a> </div>
      </div>
      <div className="col-md-6 col-lg-4">
        <div className="in"> <a href="fresh-graduates" className="fulllink">
          <div className="imgdiv"><img src="images/function/graduates.jpg" alt=""/></div>
          <h4>Fresh Graduates</h4>
          </a> <a href="fresh-graduates" className="link"><img src="images/arrowgreen.png" alt="arrow"/> Opportunities to jump start your career</a> </div>
      </div>
    </div>
  </div>
</section>
    </>
  )
}

export default Function
